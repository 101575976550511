
    import { defineComponent } from "vue";
    import vm from '@/main';
    import { courseModel} from "@/models/WorkspaceModel";
    import CategoryNodePath from "@/components/CategoryNodePath.vue";
    import WorkspaceCourseApi from "@/apis/WorkspaceCourseApi";

    export default defineComponent({
        name: "EditCouse",
        components: {
            CategoryNodePath,
        },
        data() {
            return {
                currentCourse: {
                    metadata: {name: "",type: "" },
                    imageUrl: '',
                    uuid: '',
                    courseFile:'',
                    categoryNodeId: '',
                    CategoryNodePath: '',
                    allowDuplicateName: false,
                } as courseModel,
                // name: '',
                courseFileLink:'',
                courseImageLink:'',
                action: '',
            };
        },
        props: {

        },
        computed: {
            actionOfCourse() {
                if(this.$router.currentRoute.path.includes('edit')) this.action = 'edit'
                else    this.action = 'create'
                this.currentCourse = this.$store.state.currentCourseWorkspace
                this.courseFileLink = this.currentCourse.courseFile;
                // if(this.action == 'edit'){
                //     this.currentCourse = this.$store.state.currentCourseWorkspace
                // }
            }
        },
        methods: {
            async saveEdit(){
                this.currentCourse.allowDuplicateName = false;
                console.log("categoryNodeId: "+this.currentCourse.categoryNodeId)
                console.log("name: "+this.currentCourse.metadata.name)
                console.log("imageUrl: "+this.currentCourse.imageUrl)
                console.log("courseFile: "+this.currentCourse.courseFile)
                try{
                    this.$store.commit('updateLoading', true);
                    const createCourse = JSON.parse(JSON.stringify(await WorkspaceCourseApi.updateCourse(this.currentCourse)));
                    this.$router.push({ path: "/workspace/course" });
                }catch(err){
                    //重複課名會出現error409
                    console.log(err);
                    const courseName = this.currentCourse.metadata.name;
                    this.$bvModal.msgBoxConfirm(`「${courseName}」名称重複。要允许重复或修正吗？`,{
                        title: 'Please Confirm',
                        size: 'sm',
                        buttonSize: 'sm',
                        okVariant: 'danger',
                        okTitle: '修正',
                        cancelTitle: '允许',
                        footerClass: 'p-2',
                        hideHeaderClose: true,
                        centered: true
                    }).then(async (confirmed) => {
                        if (confirmed) {
                            // 使用者按下「修正」
                            console.log("用户按下了修正");
                        } else {
                            // 使用者按下「允許」
                            console.log("用户按下了允许重複名称");
                            this.$store.commit('updateLoading', true);
                            try{
                                this.currentCourse.allowDuplicateName = true;
                                const createCourse = JSON.parse(JSON.stringify(await WorkspaceCourseApi.updateCourse(this.currentCourse)));
                                this.$router.push({ path: "/workspace/course" });
                            }
                            catch(e){
                                console.log(e);
                            }
                            finally{
                                this.$store.commit('updateLoading', false);
                                alert("命名成功");
                            }
                        }
                    })
                }finally{
                    this.$store.commit('updateLoading', false);
                }

            },
            getCategoryId(id: string){
                this.currentCourse.categoryNodeId = id;
            }
        },
        created(){
            this.actionOfCourse
        }
    });
